<script setup lang="ts">
import ChartSourcesPercentage from '@/views/dashboard/ChartSourcesPercentage.vue'
import {useDashboardStore} from '@/store/dashboard'
import ChartInfo from "@/views/dashboard/ChartInfo.vue";

const store = useDashboardStore()

store.loadDashboard()

const statistics = computed(() => [

  {
    title: 'Відвідувачів зі сторонніх вебсайтів',
    stats: store.dashboard?.week?.sourceWeb || 0,
    icon: 'mdi-account-multiple',
    color: 'warning',
  },

  {
    title: 'Відвідувачів з пошуких систем і мобікард',
    stats: (parseInt(store.dashboard?.week?.sourceSearch) + parseInt(store.dashboard?.week?.sourceMobicard)) || 0,
    icon: 'mdi-search-web',
    color: 'info',
  },

  {
    title: 'Відвідувачів з instagram',
    stats: store.dashboard?.week?.sourceInstagram || 0,
    icon: 'mdi-instagram',
    color: 'error',
  },

  {
    title: 'Натиснуло на один з контактів',
    stats: store.dashboard?.week?.contactClicks || 0,
    icon: 'mdi-phone',
    color: 'success',
  },
])
</script>

<template>
  <div>
    <VCard
      title="За останні 7 днів:"
      class="mb-4"
    >
      <VCardText>
        <VRow>
          <VCol
            v-for="item in statistics"
            :key="item.title"
            cols="6"
            md="3"
          >
            <div class="d-flex align-center">
              <VAvatar
                :color="item.color"
                rounded
                size="42"
                class="elevation-1 me-3"
              >
                <VIcon
                  size="24"
                  :icon="item.icon"
                />
              </VAvatar>

              <div class="d-flex flex-column">
                <span class="text-caption">
                  {{ item.title }}
                </span>
                <span class="text-h6">{{ item.stats }}</span>
              </div>
            </div>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
    <VRow class="mb-3">
      <VCol
        cols="12"
        md="7"
      >
        <ChartInfo v-if="store.dashboard?.daily" />
      </VCol>
      <VCol
        cols="12"
        md="5"
      >
        <ChartSourcesPercentage v-if="store.dashboard?.week" />
      </VCol>
    </VRow>
    <VCard
      title="Ваша присутність у пошукових індексах"
      class="mb-4"
    >
      <VCardText>
        <p>
          Що таке пошукові індекси від мобікард читайте у статті: <a
            href="https://mobicard.com.ua/service/search-catalog-mobicard"
            target="_blank"
          >Пошукові каталоги мобікард</a>
        </p>
        <VTable>
          <thead>
            <tr>
              <th class="text-left">
                Назва індексу
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!store.dashboard?.searchIndexes || store.dashboard?.searchIndexes.length === 0">
              <td>
                Ваш профіль ще не бере участь в пошукових індексах. Спробуйте додати більше послуг.
              </td>
            </tr>
            <tr v-for="search in store.dashboard?.searchIndexes">
              <td>
                <a
                  :href="`https://mobicard.com.ua/search/${search.seoSlug}`"
                  target="_blank"
                >
                  <VIcon icon="mdi-link" />
                  {{ search.title }}
                </a>
              </td>
            </tr>
          </tbody>
        </VTable>
      </VCardText>
    </VCard>
  </div>
</template>
