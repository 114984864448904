import { api } from '@/helpers/api'
import { useAppStore } from '@/store/app'
import type { UserAttributes } from '@/types/UserAttributes'
import type { AuthResponse } from '@/types/responses/AuthResponse'
import type { ResourceResponse } from '@/types/responses/ResourceResponse'

export function isAuthenticated(): boolean {
  return !!localStorage.getItem('userToken')
}

export function loadUser(): void {
  const store = useAppStore()

  if (!localStorage.getItem('userToken') || !localStorage.getItem('user'))
    return

  try {
    const userAuthToken = localStorage.getItem('userToken') as string
    const user = JSON.parse(localStorage.getItem('user') as string) as unknown as UserAttributes

    store.setToken(userAuthToken)
    store.setUser(user)
  }
  catch (error) {
    console.error('Unable to load user')
  }
}

export async function refreshUser(): Promise<UserAttributes | false> {
  const result = await api<ResourceResponse<UserAttributes>>({
    endpoint: 'users/me',
    method: 'get',
  })

  if (!result.data.data)
    return false

  const user = result.data.data

  useAppStore().setUser(user)
  localStorage.setItem('user', JSON.stringify(user))

  return user
}

export function authenticate(user: UserAttributes, token: string): void {
  const store = useAppStore()

  store.setUser(user)
  store.setToken(token)

  localStorage.setItem('userToken', token)
  localStorage.setItem('user', JSON.stringify(user))
}

/**
 * @todo fix to refresh only when token expire
 */
export async function refreshToken() {
  const store = useAppStore()

  const result = await api<AuthResponse>({
    endpoint: 'refresh',
    method: 'post',
  })

  if (result.status !== 200)
    return

  const token = result.data.access_token

  store.setToken(token)
  localStorage.setItem('userToken', token)
}

export function logout(): void {
  const store = useAppStore()

  store.setUser(null)
  store.setToken(null)

  localStorage.setItem('userToken', '')
  localStorage.setItem('user', '')
}

export function getUser(): UserAttributes {
  const store = useAppStore()

  if (!store.user)
    throw new Error('User not logged in')

  return store.user
}

export function accessToken(): string {
  const store = useAppStore()

  if (!store.token)
    throw new Error('User not logged in')

  return store.token
}
