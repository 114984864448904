import {api} from "@/helpers/api";
import {DashboardResponse} from "@/types/responses/DashboardResponse";

interface DashboardState {
    dashboard: DashboardResponse | null;
    loading: boolean;
}

export const useDashboardStore = defineStore('dashboard', {
    state: () : DashboardState => ({
        dashboard: null,
        loading: true,
    }),

    actions: {
        async loadDashboard() {
            this.loading = true;

            const result = await api<DashboardResponse>({
                method: 'get',
                endpoint: 'dashboard',
            });

            this.loading = false;
            this.dashboard = result.data;
        }
    }
})
