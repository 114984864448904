import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import {isAuthenticated, loadUser} from "@/helpers/auth";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...setupLayouts(routes),
  ],
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

router.beforeEach((to, _, next) => {
    const loginRouteNames = [
        'login',
        'register'
    ];

    if (isAuthenticated()) {
        loadUser();

        if (loginRouteNames.includes(to.name as string)) {
            return next({ name: 'index' });
        }

        return next();
    }

    if (!loginRouteNames.includes(to.name as string)) {
        return next({ name: 'login' });
    }

    return next();
});


export default router;
