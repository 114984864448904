<script lang="ts" setup>
import VueApexCharts from 'vue3-apexcharts'
import { useDisplay, useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useDashboardStore } from '@/store/dashboard'

const vuetifyTheme = useTheme()
const { theme } = useThemeConfig()
const { thresholds } = useDisplay()

const store = useDashboardStore()

const options = controlledComputed(theme, () => {
  const currentTheme = ref(vuetifyTheme.current.value.colors)
  const variableTheme = ref(vuetifyTheme.current.value.variables)

  const disabledColor = `rgba(${hexToRgb(currentTheme.value['on-surface'])},${variableTheme.value['disabled-opacity']})`
  const borderColor = `rgba(${hexToRgb(String(variableTheme.value['border-color']))},${variableTheme.value['border-opacity']})`

  return {
    chart: {
      stacked: true,
      parentHeightOffset: 0,
      toolbar: { show: true },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '30%',
        endingShape: 'rounded',
        startingShape: 'rounded',
      },
    },
    xaxis: {
      axisTicks: { show: false },
      crosshairs: { opacity: 0 },
      axisBorder: { show: false },
      categories: store.dashboard?.daily?.map(day => day.statAt) ?? [],
      labels: { style: { fontSize: '12px', colors: disabledColor, fontFamily: 'inter' } },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          colors: disabledColor,
          fontFamily: 'inter',
        },
        formatter: (value: number) => (value > 999 ? `${(value / 1000).toFixed(0)}k` : `${value}`),
      },
    },
    colors: [
        currentTheme.value.warning,
        currentTheme.value.error,
        currentTheme.value.info,
        currentTheme.value.success,
        currentTheme.value.primary
    ],
    grid: {
      borderColor,
      strokeDashArray: 7,
      padding: { bottom: -10 },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    stroke: {
      width: 6,
      curve: 'smooth',
      lineCap: 'round',
      colors: [currentTheme.value.surface],
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    responsive: [
      {
        breakpoint: thresholds.value.xl,
        options: { plotOptions: { bar: { columnWidth: '45%' } } },
      },
      {
        breakpoint: thresholds.value.lg,
        options: { plotOptions: { bar: { columnWidth: '50%' } } },
      },
      {
        breakpoint: thresholds.value.sm,
        options: { plotOptions: { bar: { columnWidth: '45%' } } },
      },
      {
        breakpoint: 430,
        options: { plotOptions: { bar: { columnWidth: '55%' } } },
      },
    ],
  }
})

const series = [
  {
    name: 'Відвідувачів зі сторонніх вебсайтів',
    data: store.dashboard?.daily?.map(day => parseInt(day.sourceWeb)) ?? [],
  },
  {
    name: 'Відвідувачів з інстаграм',
    data: store.dashboard?.daily?.map(day => parseInt(day.sourceInstagram)) ?? [],
  },
  {
    name: 'Відвідувачів з пошукових систем + мобікард',
    data: store.dashboard?.daily?.map(day => parseInt(day.sourceMobicard) + parseInt(day.sourceSearch)) ?? [],
  },
  {
    name: 'З них клікнуло один з контактів',
    data: store.dashboard?.daily?.map(day => parseInt(day.contactClicks)) ?? [],
  },
  {
    name: 'Переглядів будь яких послуг, суммарно',
    data: store.dashboard?.daily?.map(day => parseInt(day.articleViews)) ?? [],
  },
]
</script>

<template>
  <VCard>
    <VRow no-gutters>
      <VCol
        cols="12"
      >
        <VCardText>
          <h6 class="text-h6">
            Статистика сторінки
          </h6>

          <VueApexCharts
            type="bar"
            :options="options"
            :series="series"
            :height="280"
          />
        </VCardText>
      </VCol>
    </VRow>
  </VCard>
</template>

<style lang="scss">
$responsive-border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));

@media screen and (min-width: 600px) {
    .responsive-border {
        border-inline-end: $responsive-border;
    }
}

@media screen and (max-width: 599px) {
    .responsive-border {
        border-block-end: $responsive-border;
    }
}
</style>
