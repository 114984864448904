import {UserAttributes} from "@/types/UserAttributes";

interface AppState {
    hasAlert: boolean;
    alert: {type: 'error' | 'warning'| 'success' | 'info', message: string} | null;
    loading: boolean;
    user: UserAttributes | null;
    token: string | null;
}

export const useAppStore = defineStore('app', {
    state: () : AppState => ({
        hasAlert: false,
        alert: null,
        loading: false,
        user: null
    }),

    actions: {
        setAlert(type: AppState['alert']['type'], message: string) {
            this.hasAlert = true;
            this.alert = {type, message};

            setTimeout(() => this.hasAlert = false, 5 * 1000);
        },

        loading(set: boolean) {
            this.loading = set;
        },

        setUser(user: UserAttributes) {
            this.user = user;
        },

        setToken(token: string) {
            this.token = token;
        },
    }
})
