<script setup lang="ts">
import {useRegisterSW} from 'virtual:pwa-register/vue'

const {
    offlineReady,
    needRefresh,
    updateServiceWorker,
} = useRegisterSW()

const close = async () => {
    offlineReady.value = false
    needRefresh.value = false
}
</script>

<template>
    <div
        v-if="needRefresh"
        class="pwa-toast"
        role="alert"
    >
        <div class="message">
            Натисніть, щоб оновити версію дотатку:
        </div>
        <VBtn v-if="needRefresh" @click="updateServiceWorker()">
            Оновити
        </VBtn>
        <VBtn color="default" @click="close">
            Закрити
        </VBtn>
    </div>
</template>

<style>
.pwa-toast {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 16px;
    padding: 12px;
    border: 1px solid #8885;
    border-radius: 4px;
    z-index: 100000;
    text-align: left;
    box-shadow: 3px 4px 5px 0 #8885;
    background-color: white;
}

.pwa-toast .message {
    margin-bottom: 8px;
}

.pwa-toast button {
    border: 1px solid #8885;
    outline: none;
    margin-right: 5px;
    border-radius: 2px;
    padding: 3px 10px;
}
</style>
