const __pages_import_0__ = () => import("/src/pages/assistant/index.vue");
const __pages_import_1__ = () => import("/src/pages/settings.vue");
const __pages_import_2__ = () => import("/src/pages/register.vue");
const __pages_import_3__ = () => import("/src/pages/qr.vue");
const __pages_import_4__ = () => import("/src/pages/plans.vue");
const __pages_import_5__ = () => import("/src/pages/login.vue");
import __pages_import_6__ from "/src/pages/index.vue";
const __pages_import_7__ = () => import("/src/pages/card.vue");
const __pages_import_8__ = () => import("/src/pages/[...all].vue");
const __pages_import_9__ = () => import("/src/pages/assistant/[id].vue");

const routes = [{"name":"assistant","path":"/assistant","component":__pages_import_0__,"props":true},{"name":"settings","path":"/settings","component":__pages_import_1__,"props":true},{"name":"register","path":"/register","component":__pages_import_2__,"props":true,"meta":{"layout":"blank"}},{"name":"qr","path":"/qr","component":__pages_import_3__,"props":true},{"name":"plans","path":"/plans","component":__pages_import_4__,"props":true},{"name":"login","path":"/login","component":__pages_import_5__,"props":true,"meta":{"layout":"blank"}},{"name":"index","path":"/","component":__pages_import_6__,"props":true},{"name":"card","path":"/card","component":__pages_import_7__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_8__,"props":true,"meta":{"layout":"blank","action":"read","subject":"Auth"}},{"name":"assistant-id","path":"/assistant/:id","component":__pages_import_9__,"props":true}];

export default routes;